import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

export function BancoDeDados() {
  const [db, setDb] = useState([]);
  console.log("att")
  const [reload,setReload] = useState(false)

  function atualizar(){
    setReload(!reload)
    setDb([])
  }
  
  useEffect(() => {
    async function getDb() {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/tracking');
        setDb(response.data);
        console.log(response.data)
      } catch (error) {
        setDb([]);
      }
    }
    getDb();
  }, [reload]);

  return (
    <>
      <button onClick={atualizar}>Atualizar Dados</button>
      
      {db.length > 0 ? (
        <div>
            <Div>
            <Div1>Name</Div1>
            <Div1>Number</Div1>
            <Div1>Order Status</Div1>
            <Div1>Email</Div1>
            <Div1>CPF</Div1>
            <Div2>Container</Div2>
            </Div>
          {db.map((item, index) => (
            <Div key={index}>
              <Div1> {item.Name}</Div1>
              <Div1> {item.Number}</Div1>
              <Div1> {item["Order-Status"]}</Div1>
              <Div1>{item.Email}</Div1>
              <Div1>{item.CPF}</Div1>
              <Div2> {item.container}</Div2>
            </Div>
          ))}
        </div>
      ) : (
        <div>Dados não encontrados.</div>
      )}
    </>
  );
}
const Div = styled.div`
display: flex;
width: 100%;
`
const Div2 = styled.div`
width: 10%;
height: 3vw;

border-width:revert;
border-style: solid;
border-color: black; 
`

const Div1 = styled.div`
width: 18%;
height: 3vw;

border-width:revert;
border-style: solid;
border-color: black; 
`