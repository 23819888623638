import React, { useEffect,useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import * as XLS from 'xlsjs';
import axios from "axios";
import styled from 'styled-components';
import Context from "../context"


function Dashboard() {
  const [sqlQueries, setSqlQueries] = useState('');
  const [container, setContainer] = useState('')
  const token = localStorage.getItem('token');
  const { logged, setLogged} = useContext(Context)
  const enviar = { headers: { Authorization: token } }
  console.log(logged)

  useEffect(()=>{
    const fetchData = async ()=>{
      try {
        const check =await axios.post(process.env.REACT_APP_API_URL + '/checktoken' ,enviar)
        console.log(check.data,"check")
        if(check.data){
          setLogged(true)
          console.log("entrou")
        }else{
          console.log("entousds")
          setLogged(false)
        }
      } catch (error) {
        
      }
    }
    fetchData()
  })




  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
    
      let workbook;
      if (file.name.endsWith('.xls')) {
        workbook = XLS.read(data, { type: 'array' });
      } else {
        workbook = XLSX.read(data, { type: 'array' });
      }
    
      let sqlQueries = '';
    
      const sheetName = workbook.SheetNames[0]; // Obtenha o nome do primeiro sheet
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
      setContainer(json[4][5])
      console.log(container)
    
      // Converta JSON para consultas SQL
      const sheetSqlQueries = json.map((row) => {
        const columns = row.map((cell) => `'${cell}'`).join(', ');
        return `INSERT INTO pedidos VALUES (${columns});`;
      });
    
      sqlQueries = sheetSqlQueries.join('\n');
      setSqlQueries(sqlQueries);
      console.log(sqlQueries)
    };
    
    reader.readAsArrayBuffer(file);
  };
  async function send(){
    const token = localStorage.getItem('token');
    const enviar = { headers: { Authorization: token } }
    const payload = {sqlQueries:sqlQueries,enviar}
    console.log(container)
    try {
       const track = axios.post(process.env.REACT_APP_API_URL + '/insert' ,payload)
       alert((await track).data)
         sendEmail()
    } catch (error) {
      alert(error)
      console.log(error)
    }
  }

  async function sendEmail(){
    const payload = {container:container}
    console.log(payload)
    try {
      const email = await axios.post(process.env.REACT_APP_API_URL + '/email', payload)
      alert("E-mails disparados com sucesso.")

    } catch (error) {
      alert(error)
    }

  }


  return (
    <Div>
      <spam></spam>
      <h1>ENVIAR EXCEL DE ENCOMENDA</h1>
      <input type="file" accept=".xls, .xlsx" onChange={handleFileUpload} />
      <pre></pre>
      <button onClick={send}>Enviar</button>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>

    </Div>
  );
}

export default Dashboard;

const Div = styled.div` 
align-items: center;
  display: flex;
  width: 50vw;
  height: 20vw;
  flex-direction: column;
  justify-content: space-between;
  border-width: medium;
  border-style: solid;
  border-color: black;
  & button{
    width: 100px;
  }`