import Dashboard from "./Dashboard"
import UpdateOne from "./UpdateOne"
import Update from "./Update"
import styled from "styled-components"
import LoginPage from "./loginPage"
import Context from "../context"
import { useState } from "react"
import UpdateByRoute from "./UpdateByRoute"
import { BancoDeDados } from "./BancoDeDados"



export function GeralDash() {
    const [logged, setLogged] = useState(false)

    return (<>
        <Context.Provider value={{ logged, setLogged }}>
            {logged ? <>
                <Layout>
                    <Dashboard></Dashboard>
                    <Update></Update>
                    <UpdateOne></UpdateOne>
                    <UpdateByRoute></UpdateByRoute>
                    </Layout><BancoDeDados></BancoDeDados></>
                : <>
                    <Layout2><LoginPage></LoginPage></Layout2></>}
        </Context.Provider>
    </>
    )
}

const Layout = styled.div`
display:grid;
grid-template-columns: repeat(2, 1fr);
width: 100%;
margin-top: 20px;
height: 100%;
justify-content: space-between;
`
const Layout2 = styled.div`
display:flex;
justify-content: space-around;
font-size: 50px;
`