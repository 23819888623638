import React, { useContext, useEffect, useState } from 'react';
import Context from "../context"
import axios from 'axios';
import styled from 'styled-components';
export default function LoginPage() {
  
  useEffect(()=>{
    const token = localStorage.getItem('token');
    if(token) setLogged(true)
  })



  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { logged, setLogged} = useContext(Context)
  const [loading, setLoading] = useState(false)

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  async function handleFormSubmit(event) {
    setLoading(true)

    event.preventDefault();
    try {
        const loggin = await axios.post(process.env.REACT_APP_API_URL + '/login', {username:username,password:password})
        localStorage.setItem("token",loggin.data)
        if(loggin) setLogged(true)

    } catch (error) {
        alert("login ou senha incorrecto")
        setLoading(false)
    }  };

  return (
    <Layout>
      Sanshin Dashboard
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>Username:</label>
          <Input type="text" value={username} onChange={handleUsernameChange} />
        </div>
        <div>
          <label>Password:</label>
          <Input type="password" value={password} onChange={handlePasswordChange} />
        </div>
        <Button type="submit">Login</Button>
        {loading?<Loading></Loading>:<></>}
      </form>
    </Layout>
  );
};


const Input = styled.input`
width: 10vw;
`

const Button = styled.button`
width:5vw;
height: 2vw;
position: absolute;
top: 12vw;
left:10vw;
`
const Layout = styled.div`
position: relative;
margin-top:3vw;`


const Loading = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 10px;
  border-radius: 50%;
  border: 10px solid #555;
  border-top-color: #f8b26a;
  animation: loader-circle 1s linear infinite;
  @keyframes loader-circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}`