import styled from "styled-components"
import { useState } from "react";
import axios from "axios";

export default function Update() {
    const [updatedPlace, setUpdatedPlace] = useState('Alfândega BR');
    const [containerNumber, setContainerNumber] = useState("")
    let payload


    async function updateContainer(e) {
        e.preventDefault();
        const token = localStorage.getItem('token');
        console.log(token)
        const enviar = { headers: { Authorization: token } }
        console.log(enviar)
        console.log(updatedPlace)
        console.log(containerNumber)
        if(updatedPlace=="Apagar"){
            console.log(containerNumber)
            try {
                const up = await axios.put(process.env.REACT_APP_API_URL + '/delete', {containerNumber:containerNumber})
                console.log(up)
                alert("DELETADO")
            } catch (error) {
                alert("ERRO")
                return ("ERRO")
            }

        }else{
        const payload = [updatedPlace, containerNumber]
        try {
            const token = localStorage.getItem('token');
            const enviar = { headers: { Authorization: token } }
            const up = await axios.post(process.env.REACT_APP_API_URL + '/update', payload)
            console.log(up)
            alert("Atualizado")
        } catch (error) {
            alert("Erro")
            return ("ERRO")
        }}
    }
    return (<Atualizar>
        <Div>   ATUALIZAR CONTAINER</Div>
        <Form onSubmit={updateContainer}>
            <Input placeholder="Numero do container" onChange={(event) => setContainerNumber(event.target.value)} />
            <Select name="exemplo1" id="update" onChange={(event) => setUpdatedPlace(event.target.value)} >
                <option value="Coleta">Coleta</option>
                <option value="Depósito JP">Depósito JP</option>
                <option value="Bagagem em Transito Maritimo">Bagagem em Transito Maritimo</option>
                <option value="Alfândega BR">Alfândega BR</option>
                <option value="Destino Final">Destino Final</option>
                <option value="Entregue">Entregue</option>
                <option value="Apagar">APAGAR</option>
            </Select>
            <Button >Atualizar</Button>

        </Form>
    </Atualizar>
    )
}
const Form = styled.form`
display: flex;
flex-direction: column;
width:200px;
`

const Atualizar = styled.div`
    display:flex;
    flex-direction: column;
    border-width: medium;
    align-items: center;
  border-style: solid;
  width: 50vw;
  height: 20vw;
  border-color: black;
  
`
const Button = styled.button`
width: 200px;
height: 30px;
margin-top: 1vw;
`

const Input = styled.input`
margin-top:1vw;`
const Select = styled.select`
margin-top:1vw`
const Div = styled.div`
margin-top: 2vw;
`