import styled from "styled-components"
import { useState } from "react";
import axios from "axios";

export default function UpdateOne() {
    const [updatedPlace, setUpdatedPlace] = useState('Alfândega BR');
    const [orderNumber, setOrderNumber] = useState("")
    console.log(updatedPlace,orderNumber)

    async function updateContainer(e) {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const enviar = { headers: { Authorization: token } }
        console.log(updatedPlace)
        const payload = {updatedPlace, orderNumber}
        try {
            const up = await axios.post(process.env.REACT_APP_API_URL + '/updateone',payload)
            console.log(up)
            alert("Atualizado")
        } catch (error) {
            return ("ERRO")
        }
    }
    return (<Atualizar>
        <Div>ATUALIZAR APENAS UMA ENCOMENDA</Div>
        <Form onSubmit={updateContainer}>
            <input placeholder="Numero da encomenda" onChange={(event) => setOrderNumber(event.target.value)} />
            <Select name="exemplo1" id="update" onChange={(event) => setUpdatedPlace(event.target.value)} >
                <option value="Coleta">Coleta</option>
                <option value="Depósito JP">Depósito JP</option>
                <option value="Trânsito Marítimo">Trânsito Marítimo</option>
                <option value="Alfândega BR">Alfândega BR</option>
                <option value="Destino Final">Destino Final</option>
                <option value="Entregue">Entregue</option>
            </Select>
            <Button >Atualizar</Button>

        </Form>
    </Atualizar>
    )
}
const Form = styled.form`
display: flex;
flex-direction: column;
width:200px;
margin-top: 1vw;
`
const Select = styled.select`
margin-top:1vw;`

const Atualizar = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
  height: 20vw;
    border-width: medium;
  border-style: solid;
  border-color: black;
  display: flex;
  flex-direction: column;
`
const Button = styled.button`
margin-top: 1vw;
width: 200px;
height: 30px;
`
const Div = styled.div`
margin-top: 2vw;
`