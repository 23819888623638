import React from 'react';
import * as XLSX from 'xlsx';
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";

export default function UpdateByRoute() {
  const [jsonData, setJsonData] = useState([]);

  async function sendToBack(jsonData) {
    const token = localStorage.getItem('token');
    const enviar = { headers: { Authorization: token } }
    console.log(jsonData)
    try {
        let string = `UPDATE pedidos SET "Order-Status" = '${jsonData[1][2]}' Where "Number" IN (`
        for (let i = 1; i < jsonData.length; i++) {
            if (i !== jsonData.length) {
                string = string + "'" +(`${jsonData[i][1]}'`)
            } if (i < jsonData.length - 1) {
                string = string + (",")
            }if(i==jsonData.length-1){
                string = string + (")")
            }
        }
        console.log(string)
      const update = await axios.post(
        process.env.REACT_APP_API_URL + "/updatebyroute",{string}
      );
      console.log(update.data)
      alert("atualizado")
    } catch (error) {
      alert("erro");
      console.log(error);
    }
  }

  function handleFileSelect(event) {
    const files = event.target.files;
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });


      sendToBack(jsonData);

      setJsonData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  }

  return (
    <Div>
      <spam></spam>
      <Div2>ATUALIZAR POR ROTA</Div2>
      <Input type="file" onChange={handleFileSelect} />
      <Button type='submit'>Enviar</Button>
      <spam></spam>
      <spam></spam>
      <spam></spam>
      <spam></spam>
      <spam></spam>

    </Div>
  );
}

const Div = styled.div`
display:flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
border-width:medium    ;
border-style: solid;
border-color: black;
`
const Div2 = styled.div`

`

const Input = styled.input`
height: 5vw;
margin-top:10px;`

const Button = styled.button`
width: 200px;
height: 30px;
border-radius:3px;`
