import styled from "styled-components"
import logo from "../imgs/removal.ai_tmp-62726e61e7157-e1689779815623.png"
import { Rastreio } from "./Rastreio"
import { useNavigate } from "react-router-dom"
import { GiHamburgerMenu } from 'react-icons/gi';
import {AiOutlineCloseSquare} from 'react-icons/ai';
import { useState } from "react";

export default function NavBar() {
    const [clicked, setClicked] = useState(false)
    function mobilemenu(){
        setClicked(!clicked)
    }

    const navigate = useNavigate();
    return (
        <>
            <NavBarContainer>
            <Mobile>
                    {clicked ? <Click>
                        <Logo mobile src={logo} />
                        <AiOutlineCloseSquare onClick={mobilemenu} />
                        <> <A mobile href="https://sanshin-mudancas.com/" >
                            <Home mobile > HOME</Home>
                        </A>
                        <Linha/>

                        <A mobile href="https://sanshin-mudancas.com/sobre-nos/" >
                            <Home mobile > SOBRE NÓS</Home>
                        </A> <Linha/>
                        <A mobile href="https://sanshin-mudancas.com/nossos-servicos/" >
                            <Home mobile > NOSSOS SERVIÇOS</Home>
                        </A> <Linha/>
                        <A mobile href="https://sanshin-mudancas.com/contato/" >
                            <Home mobile > CONTATO</Home>
                        </A> <Linha/>

                        <A mobile ativo  >
                            <Home mobile > RASTREAMENTO</Home>
                        </A> <Linha/>
                        <A mobile href="https://sanshin-mudancas.com/loja/" >
                            <Home mobile > EMBALAGENS</Home>
                        </A> <Linha/> </>
                       
                    </Click> : <GiHamburgerMenu menu  onClick={mobilemenu} ></GiHamburgerMenu>}

                </Mobile>

                <spam></spam>        <spam></spam>

                <spam></spam>
                <Logo src={logo} />
                <spam></spam>
                
                <A href="https://sanshin-mudancas.com/" >
                    <Home > HOME</Home>
                </A>

                <A href="https://sanshin-mudancas.com/sobre-nos/" >
                    <Home > SOBRE NÓS</Home>
                </A>
                <A href="https://sanshin-mudancas.com/nossos-servicos/" >
                    <Home > NOSSOS SERVIÇOS</Home>
                </A>
                <A href="https://sanshin-mudancas.com/contato/" >
                    <Home > CONTATO</Home>
                </A>

                <A  >
                    <Home > RASTREAMENTO</Home>
                </A>        <A href="https://sanshin-mudancas.com/loja/" >
                    <Home > EMBALAGENS</Home>
                </A>
                <spam></spam>
                <spam></spam>
                <spam></spam>
                <spam></spam>
                <spam></spam><spam></spam>
                <spam></spam><spam></spam><spam></spam>
            </NavBarContainer>
            <Rastreio></Rastreio>
        </>

    )
}


const Linha = styled.div`
height:2px;
background-color:white;
width: 100%;
z-index: 1;

`

const Click = styled.div`
display: none;
@media (max-width: 768px) {
display: flex;
flex-direction: column;
position: fixed;
background-color: #152C59;
top: 0px;
left: 0px;
width: 66%;
height: 100%;
}svg{
    color: white;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 45px;
    height: 45px;
}

`

const A = styled.a`
display:${props => props.mobile?"none":"flex"};
align-self: center;
color: white;
font-weight: bold;
text-decoration: none;
height: 50%;
:hover{
    background-color: #EA4E0B ;
};
@media (max-width: 768px) {
    background-color: ${props=>props.ativo?"#EA4E0A":""};
    display: flex;
    flex-direction: column;
    width:${props=> props.mobile?"100%":"0"};
    height: 44px;
    font-weight: normal;
    justify-content:center
    ;

}
`

const NavBarContainer = styled.div`
display:flex;
position: relative;
justify-content: space-around;
background-color: #0B2C59;
width: 100vw;
height: 110px;
color: white;
@media (max-width: 768px) {
    height: 120px;
    display: grid;
    justify-content: initial;

}
`

const Logo = styled.img`
@media (max-width: 768px) {
    height:${props=> props.mobile?"80px":"100px"};
    width:${props=> props.mobile?"80px":"100px"};
    margin-top:${props=> props.mobile?"4vw":"2vw"};
    margin-left:${props=> props.mobile?"4vw":"2vw"};
    margin-bottom: ${props=> props.mobile?"5vw":"0vw"};
}
`

const Home = styled.div`
align-self:${props => props.mobile?"flex-start":"center"};
font-size: 14px;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
@media (max-width: 768px) {
display: ${props=> props.mobile?"flex":"none"};
justify-content: flex-start;
margin-left: 15px;
font-size: 14px;
font-weight: 600;


}
`


const Mobile = styled.div`
display: none;
@media (max-width: 768px) {
    z-index: 1;
    display: flex;
    position: fixed;
    left: 45%;
    top: 30px;
    color: white;
}svg{
    width:${props=>props.menu?"42px":"50px"};
    height: ${props=>props.menu?"42px":"50px"};
}
`