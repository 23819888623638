import axios from "axios";
import { Children, useState } from "react"
import styled from "styled-components"
import 'react-step-progress-bar/styles.css';
import { CustomProgressBar } from "./TrackProgressbar";


export function Rastreio() {
    const [codigo, setCodigo] = useState("")
    let currentStep = 5;
    const totalSteps = 6;
    const [searchData, setSearchData] = useState("")
    const [notFound,setNotFound] = useState(false)
    const [loading, setLoading] = useState(false)
    let i
    
    async function rastrear(e) {
        setLoading(true)
        e.preventDefault();
        const newCodigo = codigo
        const payload = { cpf: newCodigo }
        try {
            console.log(codigo) 
            console.log(payload)
            const track = await axios.post(process.env.REACT_APP_API_URL + '/tracking', payload)
            setSearchData(track.data)
            console.log(track)
            setNotFound(false)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            if(error.response.status){
                setNotFound(true)
                setSearchData("")
            } 
        }
    }

    return (
        <Conteiner><H1>Quer saber onde sua entrega está?</H1>
        <h2>Digite seu código de rastreio ou CPF do destinatário</h2>
            <Form onSubmit={rastrear}>
                <Input placeholder="Código de Rastreio" type="tel" onChange={(e) => setCodigo(e.target.value)} ></Input>
                <Button type="submit">Rastrear</Button>
                {loading?<Loading></Loading>:<></>}
            </Form>
            {searchData ? (searchData.map((s) => <div>
                <Status>
                    <Order>Encomenda #{s.Number}</Order>
                    <div>
                        <CustomProgressBar searchData={s}  totalSteps={totalSteps} />
                    </div>
                    <Status margem >Status:{s.Status}</Status>

                    {/* {s.CPF.length>=3 ?<><Fix>Cpf do destinatário:{s.CPF}</Fix></>:<></>} */}
                    {/* <Fix>Nome do destinatário:{s.Name}</Fix> */}
                </Status>
            </div>)):<></>}
            {notFound ? <>Pedido Não Encontrado</>:<></>}
        </Conteiner>
    )
}
const Button = styled.button`
width: 7vw;
height: 2vw;
margin-top: 10px;
border-radius: 5px;
background-color: #2E5AAD;
color: white;
@media (max-width: 768px) {
    height: 5vh;
    width: 24vh;
}
`

const Fix = styled.div`
@media (max-width: 768px) {
margin-left: 5%;
}
`

const Input = styled.input`
width: 20vh;
height: 2vh;
border-radius:4px;
margin-top: 1.5vw;
@media (max-width: 768px) {
    width: 24vh;
    height: 3vh;
}
`
const Order = styled.div`
  font-weight: bold;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-left: 5%;
    margin-bottom: 10%;
}
`

const Status = styled.div`
margin-bottom: 30px;
display: flex;
flex-direction:column ;
font-size: 30px;
@media (max-width: 768px) {
    margin-top: 10%;
    margin-left: ${props=>props.margem?"5%":""};
}
`

const Conteiner = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-self: center;
margin-top: 3vw;
;h2{
    color: #0B2C59;
    margin-top: 1vw;
    @media (max-width: 768px) {
        width: 80%;
    font-size:20px ;
    margin-bottom: 20px;
    margin-left: 10vw;
}

}
`
const Form = styled.form`
width: 10vw;
height: 10vw;
display: flex;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
    height: 25vw;
}
`

const H1 = styled.h1`
font-size: 30px;
color: #0B2C59;
@media (max-width: 768px) {
    margin: 20px;
    font-size: 26px;
    width: 80%;
    margin-left: 15vw;
}
`

const Loading = styled.div`
  width: 30px;
  margin-top: 10px;
  height: 30px;
  border-radius: 50%;
  border: 10px solid #555;
  border-top-color: #f8b26a;
  animation: loader-circle 1s linear infinite;
  @keyframes loader-circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}`